@use "setting" as *;

.l_kv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: pv3(800);
}

.l_contents {
  background: url(../img/page_bg.jpg) repeat-y left top / 100%;
  background-position-y: pv3(800);
  @media only screen and (max-width: 767px) {
    background: url(../img/page_bg.jpg) repeat-y left top / 100%;
  }
}

.single {
  margin: pv3(100) auto pv3(200);
  padding: pv3(80) pv3(50);
  max-width: pv3(1405);
  border-radius: pv3(30);
  background-color: $white;
  color: $black;
  font-size: pv3(18);
  @media only screen and (max-width: 767px) {
    margin: pv(40) pv(20);
    padding: pv(30) pv(20);
    max-width: 100%;
    border-radius: pv(20);
    font-size: pv(16);
  }
  .cate_list {
    display: flex;
    gap: pv3(15);
    margin: 0 0 pv3(20);
    @media only screen and (max-width: 767px) {
      gap: pv(5);
      margin: 0 0 pv(15);
    }
  }
  .category {
    display: block;
    padding: 0 pv3(23);
    width: fit-content;
    background-color: #f00;
    color: $white;
    font-size: pv3(12);
    line-height: pv3(24);
    @media only screen and (max-width: 767px) {
      padding: 0 pv(10);
      font-size: pv(12);
      line-height: pv(20);
    }
  }
  .info {
    margin: 0 auto pv3(60);
    font-size: pv3(16);
    @media only screen and (max-width: 767px) {
      margin: 0 auto pv(30);
      font-size: pv(14);
    }
  }
  .c_btn_2 {
    margin: pv3(100) auto 0;
    font-size: pv3(20);
    @media only screen and (max-width: 767px) {
      margin: pv(50) auto 0;
      width: 100%;
      font-size: pv(16);
    }
  }
  h1 {
    margin: 0 0 pv3(20);
    font-size: pv3(50);
    @media only screen and (max-width: 767px) {
      margin: 0 0 pv(20);
      font-size: pv(28);
    }
  }
  h2 {
    margin: pv3(40) 0;
    padding-bottom: pv3(15);
    border-bottom: 2px solid $color_1;
    color: $color_1;
    font-size: pv3(30);
    @media only screen and (max-width: 767px) {
      margin: pv(30) 0 pv(20);
      font-size: pv(26);
    }
    &:first-child {
      margin: 0 0 pv3(40);
      @media only screen and (max-width: 767px) {
        margin: 0 0 pv(20);
      }
    }
  }
  h3 {
    margin: pv3(40) 0;
    padding: pv3(10);
    background-color: $color_1;
    color: $white;
    font-size: pv3(26);
    @media only screen and (max-width: 767px) {
      margin: pv(30) 0 pv(20);
      padding: pv(10);
      font-size: pv(22);
    }
    &:first-child {
      margin: 0 0 pv3(40);
      @media only screen and (max-width: 767px) {
        margin: 0 0 pv(20);
      }
    }
  }
  h4 {
    margin: pv3(40) 0;
    padding-left: pv3(20);
    border-left: 5px solid $color_1;
    font-size: pv3(24);
    @media only screen and (max-width: 767px) {
      margin: pv(30) 0 pv(20);
      padding-left: pv(15);
      font-size: pv(20);
    }
    &:first-child {
      margin: 0 0 pv3(40);
      @media only screen and (max-width: 767px) {
        margin: 0 0 pv(20);
      }
    }
  }
  p {
    & + p {
      margin-top: pv3(30);
      @media only screen and (max-width: 767px) {
        margin-top: pv(20);
      }
    }
  }
  a:not([class]) {
    color: $color_1;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  ul:not([class]),
  ol:not([class]),
  ul.wp-block-list,
  ol.wp-block-list {
    margin: pv3(20) 0;
    counter-reset: ol_li;
    @media only screen and (max-width: 767px) {
      margin: pv(10) 0;
    }
  }
  .noborder ul:not([class]),
  .noborder ol:not([class]) {
    margin: 0;
    counter-reset: ol_li;
  }
  ul:not([class]) > li,
  ol:not([class]) > li,
  ul.wp-block-list > li,
  ol.wp-block-list > li {
    position: relative;
    padding-left: pv3(30);
    list-style-type: none;
    @media only screen and (max-width: 767px) {
      padding-left: pv(20);
    }
  }
  ul:not([class]) > li + li,
  ol:not([class]) > li + li,
  ul.wp-block-list > li + li,
  ol.wp-block-list > li + li {
    margin-top: pv3(4);
    @media only screen and (max-width: 767px) {
      margin-top: pv(4);
    }
  }
  ul:not([class]) > li:before,
  ul.wp-block-list > li:before {
    position: absolute;
    top: pv3(10);
    left: 0;
    display: inline-block;
    width: pv3(8);
    height: pv3(8);
    background: $color_1;
    content: "";
    @media only screen and (max-width: 767px) {
      top: pv(9);
      width: pv(8);
      height: pv(8);
    }
  }
  ol:not([class]) > li:before,
  ol.wp-block-list > li:before {
    position: absolute;
    top: 0;
    left: 0;
    color: $color_1;
    content: counter(ol_li) ".";
    counter-increment: ol_li;
    font-weight: bold;
  }
  ol:not([class]) > li,
  ol:not([class]) > li {
    padding-left: pv3(35);
    ol:not([class]) > li:before,
    ol.wp-block-list > li:before {
      position: absolute;
      top: 0;
      left: 0;
      color: #a7a7a7;
      content: "(" counter(ol_li) ")";
      counter-increment: ol_li;
      font-weight: bold;
    }
  }
  table {
    margin: pv3(20) auto;
    th {
      border: 1px solid $black;
      background-color: $color_1;
      color: $white;
    }
  }
  .wp-block-table thead {
    border: none;
  }
}

.pagenation {
  .numbox {
    display: flex;
    justify-content: center;
    gap: pv3(13);
    margin: pv3(200) auto 0;
    @media only screen and (max-width: 767px) {
      gap: pv(5);
      margin: pv(60) auto 0;
    }
    li {
      a,
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: pv3(57);
        height: pv3(57);
        border: 1px solid $white;
        background-color: rgba(255,255,255,0);
        color: $white;
        font-weight: bold;
        font-size: pv3(18);
        transition: background-color .3s, color .3s;
        @media only screen and (max-width: 767px) {
          width: pv(40);
          height: pv(40);
          font-size: pv(14);
        }
      }
      .prev,
      .next {
        width: pv3(89);
        border: none;
        font-weight: normal;
        font-size: pv3(30);
        @media only screen and (max-width: 767px) {
          width: pv(70);
        }
      }
      .current,
      a:hover {
        background-color: $white;
        color: $color_1;
      }
      .current {
        border: none;
      }
    }
  }
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: pv3(80) auto pv3(298);
  width: pv3(131);
  height: pv3(40);
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  font-size: pv3(18.32);
  line-height: 1;
  transition: background-color .3s, color .3s;
  @media only screen and (max-width: 767px) {
    margin: pv(60) auto pv(80);
    width: pv(100);
    height: pv(20);
    font-size: pv(12);
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: #fff;
      color: $color_1;
    }
  }
}
